import(/* webpackMode: "eager" */ "/vercel/path0/apps/portal/public/images/gv-icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/portal/public/images/gv-logo.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/portal/public/images/icons/social/facebook-icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/portal/public/images/icons/social/instagram-icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/portal/public/images/icons/social/pinterest.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/portal/public/images/icons/social/twitter-icon.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/libs/modules/src/shared/Header/HeaderIcon/404/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/libs/ui/src/FooterNav/FooterNavItem/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/libs/ui/src/FooterNav/NavBar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/libs/ui/src/FooterNav/NavList/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");

'use client';

import { type FC } from 'react';

import { NavBase } from '@hyex/modules/shared/Nav/NavBase';
import HeaderNavNotFound from './nav';

type PropTypes = {
  items: any[];
  logo: any;
  icon: any;
};
const HeaderNotFound: FC<PropTypes> = ({ items, logo, icon }) => {
  return (
    <>
      <header>
        <div className="container">
          <NavBase
            logo={logo}
            icon={icon}
            beforeLogo={<HeaderNavNotFound items={items} />}
            afterLogo={''}
            notFound={true}
          />
        </div>
      </header>
    </>
  );
};

export default HeaderNotFound;

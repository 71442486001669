import type { FC } from 'react';

import LinkExternal from '@hyex/ui/LinkExternal';

type PropTypes = {
  items: any[];
};

const HeaderNavNotFound: FC<PropTypes> = ({ items }) => {
  return (
    <>
      {items.map((item) =>
        item.isExternalLink ? (
          <div className="nav-submenu-link-wrapper">
            <LinkExternal href={item.href} className="nav-link">
              {item.text}
            </LinkExternal>
          </div>
        ) : (
          <div className="nav-submenu-link-wrapper">
            <a href={item.href} className="nav-link">
              {item.text}
            </a>
          </div>
        )
      )}
    </>
  );
};

export default HeaderNavNotFound;
